<script>
import { UserRoleType } from '@/utils/enums/UserRoleType';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      appTitle: 'Facelabs',
      sidebar: false,
      userRoleType: UserRoleType,
    };
  },

  computed: {
    ...mapState('auth', {
      currentUser: (state) => state.user,
    }),

    menuItems() {
      const menuItems = [];
      if (this.currentUser.role === 'STUDENT_RESPONSIBLE') {
        menuItems.push({ title: 'Meus Dados', path: '/profile/detail', icon: 'mdi-account-edit' });
      } else {
        menuItems.push({ title: 'Câmeras', path: '/live-cam', icon: 'mdi-video' });
        menuItems.push({ title: 'Histórico', path: '/history', icon: 'mdi-history' });
        menuItems.push({
          title: 'Merenda',
          icon: 'mdi-food-fork-drink',
          children: [
            { title: 'Cronograma', path: '/menu-schedule' },
            { title: 'Registros', path: '/menu-history' },
            { title: 'Itens do Cardápio', path: '/menu-item' },
            { title: 'Cardápios', path: '/menu' },
            { title: 'Listagem', path: '/menu-lists' },
          ],
        });
        menuItems.push({ title: 'Acesso', path: '/customers', icon: 'mdi-account-group' });
        menuItems.push({
          title: 'Cadastros',
          icon: 'mdi-folder-open',
          children: [
            { title: 'Alunos', path: '/students', icon: 'mdi-account-group' },
            { title: 'Turmas', path: '/student-classes', icon: 'mdi-account-group' },
            { title: 'Professores', path: '/teachers', icon: 'mdi-account-group' },
            { title: 'Dispositivos', path: '/devices', icon: 'mdi-video' },
            { title: 'Disciplinas', path: '/disciplines', icon: 'mdi-book' },
          ],
        });
        menuItems.push({
          title: 'Administrativo',
          icon: 'mdi-cog',
          children: [{ title: 'Usuários do sistema', path: '/users', icon: 'mdi-account-group' }],
        });
      }
      return menuItems;
    },

    perfilItems() {
      const perfilItems = [];
      if (this.currentUser.role === 'STUDENT_RESPONSIBLE') {
        perfilItems.push({ title: 'Perfil', path: '/profile/detail', icon: 'mdi-account-edit' });
        perfilItems.push({ title: 'Sair', path: '/logout', icon: 'mdi-power' });
      } else {
        perfilItems.push({ title: 'Perfil', path: '/profile/detail', icon: 'mdi-account-edit' });
        perfilItems.push({ title: 'Pagamentos', path: '/plan', icon: 'mdi-currency-usd' });
        perfilItems.push({ title: 'Sair', path: '/logout', icon: 'mdi-power' });
      }
      return perfilItems;
    },
  },
};
</script>

<template>
  <v-app>
    <v-app-bar fixed class="app-bar" color="background">
      <v-app-bar-nav-icon class="hidden-sm-and-up" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>

      <v-container class="page-container py-0 fill-height">
        <v-toolbar-title>
          <router-link to="/" style="cursor: pointer">
            <v-img :src="require('../../assets/logo.svg')" height="55px" :style="{ maxWidth: '170px' }" />
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-xs-only">
          <template v-for="item in menuItems">
            <v-btn v-if="!item.children" :key="'toolbar_' + item.title" :to="item.path" text>
              <v-icon left dark>{{ item.icon }}</v-icon>
              {{ item.title }}
            </v-btn>
            <v-menu v-else :key="'toolbar_' + item.title" transition="slide-y-transition" open-on-hover offset-y dark>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" text v-on="on">
                  <v-icon left dark>{{ item.icon }}</v-icon>
                  {{ item.title }}
                  <v-icon right dark>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(childrenItem, i) in item.children" :key="i" :to="childrenItem.path">
                  <v-list-item-icon v-if="childrenItem.icon">
                    <v-icon left dark>{{ childrenItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ childrenItem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-menu offset-y right open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-avatar size="42" class="mx-2 my-2">
                <img
                  v-if="currentUser && currentUser.avatarUrl"
                  v-auth-image="currentUser.avatarUrl"
                  class="px-1 py-1"
                  style="object-fit: cover"
                />
                <v-icon v-else dark size="42"> mdi-account-circle </v-icon>
              </v-avatar>
            </div>
          </template>
          <v-list>
            <div class="mx-4 mb-5 light-blue--text">
              <small>
                {{ currentUser.name }}
                <br />
                <i>{{ userRoleType[currentUser.role] }} </i>
              </small>
            </div>
            <v-list-item-group>
              <v-list-item v-for="(item, index) in perfilItems" :key="index" :to="item.path">
                <v-list-item-title class="justify-center">
                  <v-icon left size="22" class="">{{ item.icon }}</v-icon>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="sidebar" color="background" app disable-resize-watcher>
      <v-list>
        <v-list-item v-for="item in menuItems" :key="'drawer_' + item.title" :to="item.path" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="mt-16">
      <v-container class="page-container">
        <vue-page-transition name="fade-in-right">
          <router-view />
        </vue-page-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="css" scoped>
.app-bar >>> .v-toolbar__content {
  padding: 0;
}
</style>
